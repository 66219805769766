import React from 'react';
import HeroSection from "../components/HeroSection";
import WhySection from "../components/WhySection";
import GetStartedSection from "../components/GetStartedSection";
import FeaturesSection from "../components/FeaturesSection";
import ProductSection from "../components/ProductSection";
import FAQSection from "../components/FAQSection";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Secure My Phone: The Ultimate iPhone Protection App</title>
        <meta name="description" content="Safeguard your iPhone from spyware, data breaches, and unauthorized access." />
      </Helmet>
      <HeroSection/>
      <ProductSection />
      <GetStartedSection />
      <WhySection />
      <FeaturesSection />
      <FAQSection />
    </>
  );
};

export default Home;
