import React, { useRef } from "react";
import { Button, IconArrow } from "../UI";
import { getStartedConfig } from "./config";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import styles from "./GetStartedSection.module.scss";
import cn from "classnames";

const GetStartedSection = () => {
  const swiperRef = useRef(null);
  const timeoutRef = useRef(null);

  const swiperParams = {
    slidesPerView: "auto",
    centeredSlides: true,
    initialSlide: 0,
    spaceBetween: 18,
    slideActiveClass: "is-active",
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    navigation: {
      nextEl: ".js-step-next",
      prevEl: ".js-step-prev",
      disabledClass: "is-disabled",
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 25,
      },
    },
  };

  const handleSlideChange = (delay) => {
    const swiper = swiperRef.current.swiper;
    if (swiper.isEnd) {
      timeoutRef.current = setTimeout(() => {
        swiper.slideTo(0);
      }, delay);
    } else {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <section className={styles["steps"]} id="next-steps">
      <div className={cn("wrapper", styles['steps__wrapper'])}>
        <h2 className={"main-title"}>3 easy steps to get started</h2>
        <p className={cn(styles["steps__subtitle"], "main-subtitle")}>
          Detect spyware on your phone, see who tried to unlock it, protect your
          data.
        </p>

        <Swiper
          className={cn(styles["steps__slider"], "steps__slider")}
          {...swiperParams}
          ref={swiperRef}
          onSlideChangeTransitionEnd={() => handleSlideChange(5000)}
        >
          {getStartedConfig.map(({ icon, title, text }, i) => {
            return (
              <SwiperSlide
                className={styles["steps__slide"]}
                key={`review-${i}`}
              >
                <div className={styles["icon_wrap"]}>{icon}</div>
                <h3 className={styles["title"]}>{title}</h3>
                <p className={styles["description"]}>{text}</p>
              </SwiperSlide>
            );
          })}
          <div className={cn(styles["steps__navigation"], "swiper-navigation")}>
            <div
              className={cn(
                styles["steps__navigation-btn"],
                "swiper-navigation-btn",
                "js-step-prev",
              )}
            >
              <IconArrow
                className={cn(
                  styles["steps__navigation-icon"],
                  "swiper-navigation-icon",
                )}
              />
            </div>
            <div
              className={cn(
                styles["steps__navigation-btn"],
                "swiper-navigation-btn",
                "js-step-next",
              )}
            >
              <IconArrow
                className={cn(
                  styles["steps__navigation-icon"],
                  "swiper-navigation-icon",
                  "next",
                )}
              />
            </div>
          </div>
        </Swiper>
        <Button
          className={styles["steps__button"]}
          href="#"
          rel="nofollow"
          external={"true"}
        >
          Try now
        </Button>
      </div>
    </section>
  );
};

export default GetStartedSection;
